import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-admin';

import { Typography, Chip, Box } from '@material-ui/core';

const commentFields = ['comments', 'v2_low_experience_comment', 'v2_nps_reason_promoter', 'v2_nps_reason_detractor'];

const getStudentComments = (student, surveyNumber) =>
  R.pipe(
    R.propOr([], 'weekly_surveys'),
    R.find(R.propEq('survey', surveyNumber)),
    R.defaultTo({}),
    R.propOr([], 'answers'),
    R.filter(R.propSatisfies(R.includes(R.__, commentFields), 'question')),
    R.reduce((acc, { question = '', answer = '' }) => {
      if (answer) {
        acc[question] = { fullComment: answer };
      }
      return acc;
    }, {})
  )(student);

const SurveyComments = ({ record: student, surveyNumber }) => {
  let studentComments = getStudentComments(student, surveyNumber);
  const {
    data: commentInsights,
    loading: insightsLoading,
    error: insightsError,
  } = useQuery({
    type: 'getMany',
    resource: 'survey_comments',
    payload: {
      student_id: student.id,
      survey_number: surveyNumber,
    },
  });

  if (!insightsLoading && !insightsError) {
    studentComments = Object.entries(studentComments).reduce(
      (acc, [fieldKey, { fullComment }]) => {
        const insightsForField = commentInsights.filter(
          (insight) => insight.source === fieldKey
        );
        acc[fieldKey] = {
          fullComment,
          insights: insightsForField,
        };
        return acc;
      },
      {}
    );
  }

  return <CommentCard comments={studentComments}></CommentCard>;
};

const commentLabels = {
  comments: 'Comment',
  v2_low_experience_comment: 'Low Experience Comment',
  v2_nps_reason_promoter: 'NPS Promoter',
  v2_nps_reason_detractor: 'NPS Detractor'
};

const CommentCard = ({ comments }) => {
  return (
    <Box style={{ margin: '16px' }}>
      {Object.entries(comments).map(([fieldKey, { fullComment, insights }]) => (
        <Box key={fieldKey} style={{ marginBottom: '32px' }}>
          {/* Section Header */}
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontWeight: 'bold',
              marginBottom: '16px',
              color: '#333333',
              borderBottom: '1px solid #DDDDDD',
              paddingBottom: '8px',
            }}
          >
            {commentLabels[fieldKey] || fieldKey}:
          </Typography>

          {/* Full Comment */}
          <Typography
            variant="body1"
            style={{
              marginBottom: '24px',
              lineHeight: '1.8',
              color: '#555555',
              fontSize: '0.95rem',
            }}
          >
            {fullComment}
          </Typography>

          {/* Insights */}
          {!insights ? (
            <Typography
              variant="body2"
              color="text.secondary"
              style={{ fontStyle: 'italic', color: '#999999' }}
            >
              Loading insights...
            </Typography>
          ) : (
            insights.map((insight, i) => (
              <Box
                key={i}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginBottom: '16px',
                  gap: '16px',
                  padding: '12px',
                  borderRadius: '8px',
                  backgroundColor: '#F9F9F9',
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                }}
              >
                {/* Insight Type Chip */}
                <Chip
                  label={insight.type === 'Wins' ? 'Win' : 'Improvements'}
                  size="small"
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    padding: '0 12px',
                    minWidth: '150px',
                    borderRadius: '12px',
                    backgroundColor:
                      insight.type === 'Wins' ? '#E0F7FA' : '#FFEBEE',
                    color: insight.type === 'Wins' ? '#00695C' : '#B71C1C',
                  }}
                />
                <Box>
                  {/* Insight Category Chip */}
                  <Chip
                    label={insight.category}
                    size="small"
                    style={{
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      marginBottom: '8px',
                      backgroundColor: '#EEEEEE',
                      color: '#666666',
                    }}
                  />
                  {/* Insight Quote */}
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      fontStyle: 'italic',
                      color: '#666666',
                    }}
                  >
                    "{insight.quotedComment}"
                  </Typography>
                </Box>
              </Box>
            ))
          )}
        </Box>
      ))}
    </Box>
  );
};

export default SurveyComments;
