const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const surveyCommentsProvider = {
  getMany: async (params) => {
    const url = `${apiUrl}/v1/students/${params.student_id}/survey/${params.survey_number}/comments`;
    return { url };
  }
};

export default surveyCommentsProvider;
