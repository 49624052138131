import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import {
  ArrayField,
  BooleanField,
  Button,
  Datagrid,
  Error,
  FunctionField,
  Loading,
  ReferenceManyField,
  SelectField,
  Tab,
  toggleListItemExpand,
  useNotify,
  useQueryWithStore,
} from 'react-admin';
import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  DateRange,
  EventAvailable,
  AssignmentTurnedIn,
} from '@material-ui/icons';
import { orange, red } from '@material-ui/core/colors';

import { DateField, NavigableTabs, useBackend } from 'resources/common';
import { FullNameField } from 'resources/students/fields';
import { attendance } from 'data';
import { authServer } from 'auth/keycloak';

const DayAttendanceList = ({ record, week }) => {
  return (
    <Fragment>
      {!R.isEmpty(R.propOr([], 'absent', record)) && (
        <Card style={{ margin: '15px 0px' }}>
          <CardContent>
            <Typography variant="h5" component="h3" gutterBottom>
              Absent
            </Typography>
            <ArrayField label="Students" source="absent" record={record}>
              <Datagrid>
                <FullNameField sortable={false} />
              </Datagrid>
            </ArrayField>
          </CardContent>
        </Card>
      )}
      {!R.isEmpty(R.propOr([], 'late', record)) && (
        <Card style={{ margin: '15px 0px' }}>
          <CardContent>
            <Typography variant="h5" component="h3" gutterBottom>
              Late
            </Typography>
            <ArrayField label="Students" source="late" record={record}>
              <Datagrid>
                <FullNameField sortable={false} />
              </Datagrid>
            </ArrayField>
          </CardContent>
        </Card>
      )}
      {!R.isEmpty(R.propOr([], 'excused', record)) && (
        <Card style={{ margin: '15px 0px' }}>
          <CardContent>
            <Typography variant="h5" component="h3" gutterBottom>
              Excused
            </Typography>
            <ArrayField label="Students" source="excused" record={record}>
              <Datagrid>
                <FullNameField sortable={false} />
              </Datagrid>
            </ArrayField>
          </CardContent>
        </Card>
      )}
    </Fragment>
  );
};

const AddFullAttendanceButton = ({ record, cohort, addFullAttendance }) => {
  const notify = useNotify();
  const [{ loaded, error, loading }, setCall] = useBackend();
  const handleAddFullAttendance = () => {
    setCall({
      url: '/attendance',
      method: 'POST',
      body: { date: record.id, id: cohort.id },
    });
  };
  useEffect(() => {
    if (error) {
      notify(`Could not update attendance`, 'warning');
    }
  }, [error, notify]);

  useEffect(() => {
    if (loaded && !error) {
      addFullAttendance(record.id);
      notify('Attendance Updated', 'info');
    }
  }, [error, loaded]); // eslint-disable-line

  const showButton = record.timeline !== 'after' && !record.filled;
  return showButton ? (
    <MuiButton
      onClick={handleAddFullAttendance}
      disabled={loading}
      startIcon={
        loading ? (
          <CircularProgress color="inherit" size={16} />
        ) : (
          <AssignmentTurnedIn />
        )
      }
      variant="outlined"
      color="primary"
      size="small"
    >
      All In
    </MuiButton>
  ) : null;
};

const AddDayAttendanceButton = ({ record, cohort }) => {
  const showButton = record.timeline !== 'after';
  const attendance = R.evolve({
    absent: R.pluck('id'),
    late: R.pluck('id'),
    excused: R.pluck('id'),
  })(record);
  return showButton ? (
    <Button
      component={Link}
      variant="outlined"
      to={{
        pathname: '/attendance/create',
        state: { cohort, attendance },
      }}
      label={attendance.filled ? 'Edit' : 'Add'}
    >
      {attendance.filled ? <DateRange /> : <EventAvailable />}
    </Button>
  ) : null;
};

const DaysAttendanceListRowStyle = (record, index, defaultStyle = {}) => {
  if (record.timeline === 'before') {
    return { ...defaultStyle, backgroundColor: 'rgba(0, 0, 0, 0.04)' };
  } else if (record.timeline === 'today') {
    return { ...defaultStyle, boxShadow: 'inset 5px 0px 0px 0px #3f51b5' };
  } else {
    return { ...defaultStyle, backgroundColor: 'white' };
  }
};

const DaysAttendanceList = ({ record, cohort, addFullAttendance }) => {
  return (
    <ArrayField source="days" record={record}>
      <Datagrid
        rowStyle={DaysAttendanceListRowStyle}
        expand={<DayAttendanceList record={record} />}
      >
        <DateField
          label="Day"
          source="id"
          sortable={false}
          format="dddd, Do MMMM YYYY"
        />
        <FunctionField
          label="Absences"
          render={R.pipe(R.propOr([], 'absent'), R.length)}
          sortable={false}
        />
        <FunctionField
          label="Late"
          render={R.pipe(R.propOr([], 'late'), R.length)}
          sortable={false}
        />
        <FunctionField
          label="Excused"
          render={R.pipe(R.propOr([], 'excused'), R.length)}
          sortable={false}
        />
        <BooleanField label="Filled" source="filled" sortable={false} />
        {authServer.isRoles(['pm', 'teacher', 'ta', 'campus_lead']) && (
          <AddDayAttendanceButton cohort={cohort} />
        )}
        {authServer.isRoles(['pm', 'teacher', 'ta', 'campus_lead']) && (
          <AddFullAttendanceButton
            cohort={cohort}
            addFullAttendance={addFullAttendance(record.week)}
          />
        )}
      </Datagrid>
    </ArrayField>
  );
};

const ByDateTabPanelRowStyle = (record, index, defaultStyle = {}) => {
  if (record.timeline === 'today') {
    return { ...defaultStyle, boxShadow: 'inset 5px 0px 0px 0px #3f51b5' };
  } else if (record.timeline === 'before') {
    return { ...defaultStyle, backgroundColor: 'rgba(0, 0, 0, 0.04)' };
  } else {
    return { ...defaultStyle, backgroundColor: 'white' };
  }
};

const ByDateTabPanel = ({ record }) => {
  const { data, loading, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'attendance',
    payload: { id: record.id },
  });
  const classes = makeStyles({ headerRow: { display: 'none' } })();

  const [attendance, setAttendance] = useState([]);

  const addFullAttendance = R.curry((week, date) => {
    const weekIndex = R.findIndex(R.propEq('week', week), attendance);
    const dayIndex = R.findIndex(
      R.propEq('id', date),
      attendance[weekIndex].days
    );
    setAttendance(
      R.assocPath([weekIndex, 'days', dayIndex, 'filled'], true, attendance)
    );
  });

  useEffect(() => {
    if (data) {
      setAttendance(data.attendance);
    }
  }, [data]);

  const dispatch = useDispatch();
  const expanded = useSelector(
    R.pathOr([], ['admin', 'resources', 'cohorts', 'list', 'expanded'])
  );
  useEffect(() => {
    const currentWeek = R.find(R.propEq('timeline', 'today'), attendance);
    if (currentWeek) {
      if (!R.includes(currentWeek.id, expanded)) {
        dispatch(toggleListItemExpand('cohorts', currentWeek.id));
      }
    }
  }, [attendance]); // eslint-disable-line

  if (loading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  if (error)
    return (
      <Box p="16px">
        <Error />
      </Box>
    );
  return (
    <Box>
      <Datagrid
        classes={{ headerRow: classes.headerRow }}
        data={R.indexBy(R.prop('id'), attendance)}
        ids={R.pluck('id', attendance)}
        selectedIds={[]}
        loading={loading}
        loaded={!loading}
        currentSort={{ field: 'id', order: 'ASC' }}
        rowStyle={ByDateTabPanelRowStyle}
        rowClick="expand"
        expand={
          <DaysAttendanceList
            cohort={record}
            addFullAttendance={addFullAttendance}
          />
        }
      >
        <FunctionField
          label="Week"
          render={renderAttendanceWeek(record, R.pluck('id', attendance))}
          sortable={false}
        />
      </Datagrid>
    </Box>
  );
};

const AttendanceList = ({ record }) => {
  return (
    <ArrayField source="attendance" record={record}>
      <Datagrid>
        <DateField source="date" />
        <SelectField source="attendance" choices={attendance} />
      </Datagrid>
    </ArrayField>
  );
};

const AttendanceChip = ({ record: student, attendanceType }) => {
  const amount = R.pipe(
    R.propOr([], 'attendance'),
    R.reduce((total, day) => {
      return day.attendance === attendanceType ? total + 1 : total;
    }, 0)
  )(student);
  const attendanceStyles = {
    absent: R.cond([
      [R.gt(2), R.always({})],
      [R.equals(2), R.always({ color: 'black', backgroundColor: red[200] })],
      [R.lt(2), R.always({ color: 'white', backgroundColor: red[900] })],
    ]),
    late: R.cond([
      [R.gte(2), R.always({})],
      [R.lte(6), R.always({ color: 'white', backgroundColor: orange[900] })],
      [R.T, R.always({ color: 'black', backgroundColor: orange[200] })],
    ]),
    excused: R.always({}),
  };
  return (
    <Chip style={attendanceStyles[attendanceType](amount)} label={amount} />
  );
};

const ByStudentTabPanel = ({ basePath, record }) => {
  return (
    <Box>
      <ReferenceManyField
        addLabel={false}
        source="id"
        reference="students"
        target="cohort"
        perPage={40}
        record={record}
        basePath={basePath}
      >
        <Datagrid rowClick="expand" expand={<AttendanceList />}>
          <FullNameField />
          <AttendanceChip label="Absent" attendanceType="absent" />
          <AttendanceChip label="Late" attendanceType="late" />
          <AttendanceChip label="Excused" attendanceType="excused" />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
};

const AttendanceTab = (props) => {
  const classes = makeStyles({
    selected: { background: 'rgb(201, 206, 234)' },
  })();

  return (
    <NavigableTabs
      {...props}
      variant="fullWidth"
      matchUrl={R.pipe(R.split('/'), R.take(6), R.join('/'))}
    >
      <Tab
        key="calendar"
        label="By Date"
        path="calendar"
        classes={{ selected: classes.selected }}
      >
        <ByDateTabPanel />
      </Tab>
      <Tab
        key="students"
        label="By Students"
        path="students"
        classes={{ selected: classes.selected }}
      >
        <ByStudentTabPanel />
      </Tab>
    </NavigableTabs>
  );
};

const renderAttendanceWeek = R.curry((cohort, attendanceTable, attendance) => {
  if (!isExtendedCohort(cohort)) {
    return `Week ${attendance.id}`;
  } else {
    const explanation = R.cond([
      [R.equals(-1), R.always('(Prework)')],
      [R.equals(0), R.always('(Prework / M0)')],
      [R.contains(R.__, R.takeLast(2, attendanceTable)), R.always('(Career Services)')],
      [R.T, R.always('')],
    ])(attendance.id);
    return `Week ${attendance.id} ${explanation}`;
  }
});

function isExtendedCohort(cohort) {
  return (
    R.includes(cohort.campus, ['ber', 'rmt']) &&
    !R.includes(cohort.id, [
      '66f131391c68fbc535168efc',
      '66f130681c68fbc535168ef8',
      '66f130d91c68fbc535168efa',
      '66f1316e1c68fbc535168efe'
    ])
  );
}

export default AttendanceTab;
