import React from 'react';
import * as R from 'ramda';
import { Box, Typography } from '@material-ui/core';

import { surveyQuestions } from 'data';
import { getGradingColors } from 'lib/gradingColors';

const questionsMap = R.pipe(
  R.groupBy(R.prop('id')),
  R.map(R.head)
)(surveyQuestions);

const getMetricColor = (metric, value) => {
  if (metric === 'nps') {
    return getGradingColors(value / 2);
  } else {
    return getGradingColors(value);
  }
};

function SurveyMetrics({ student }) {
  const averages = R.pipe(
    R.propOr([], 'weekly_surveys'),
    R.pluck('answers'),
    R.unnest,
    R.groupBy(R.prop('question')),
    R.map(R.pluck('answer')),
    R.omit([
      'comments',
      'feelings',
      'sp_satisfaction',
      'sp_main_issue',
      'sp_secondary_issue',
      'v2_nps_reason_promoter',
      'v2_nps_reason_detractor',
      'v2_sp_ai_reason_promoter',
      'v2_sp_ai_reason_detractor',
      'v2_tech_support',
      'v2_low_experience_comment',
    ]),
    R.map(
      R.pipe(R.mean, (mean) => Math.round((mean + Number.EPSILON) * 100) / 100)
    )
  )(student);
  return !R.isEmpty(averages) ? (
    <Box display="block" m="16px">
      <Box display="flex" flexWrap="wrap">
        {R.pipe(
          R.mapObjIndexed((metric, question) => {
            const metricColors = getMetricColor(question, metric);
            return (
              <Box
                display="flex"
                borderRadius={4}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                bgcolor={metricColors.background}
                m="1em"
                p="16px"
                width="120px"
                height="120px"
                key={question}
              >
                <Box color={metricColors.typography} flex={1}>
                  <Typography variant="subtitle1" align="center">
                    {questionsMap[question].short_name}
                  </Typography>
                </Box>
                <Box color={metricColors.typography} flex={1}>
                  <Typography variant="h3">{metric}</Typography>
                </Box>
              </Box>
            );
          }),
          R.values
        )(averages)}
      </Box>
    </Box>
  ) : null;
}

export default SurveyMetrics;
