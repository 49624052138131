import actionDataProvider from './actions';
import attendanceDataProvider from './attendance';
import attendanceB2bDataProvider from './attendance_b2b';
import changeDataProvider from './changes';
import cohortDataProvider from './cohorts';
import cohortB2bDataProvider from './cohorts_b2b';
import dataDataProvider from './data';
import dropDataProvider from './drop';
import deliveryDataProvider from './deliveries';
import emailDataProvider from './emails';
import groupDataProvider from './groups';
import labsDataProvider from './labs';
import metricDataProvider from './metrics';
import moveDataProvider from './move';
import oneOnOneDataProvider from './one_on_ones';
import projectOutcomesProvider from './project_outcomes';
import surveyCommentsProvider from './survey_comments';
import studentDataProvider from './students';
import studentClusterDataProvider from './student_clusters';
import userDataProvider from './users';

export default {
  actions: actionDataProvider,
  attendance: attendanceDataProvider,
  attendance_b2b: attendanceB2bDataProvider,
  changes: changeDataProvider,
  cohorts: cohortDataProvider,
  cohorts_b2b: cohortB2bDataProvider,
  data: dataDataProvider,
  deliveries: deliveryDataProvider,
  emails: emailDataProvider,
  drop: dropDataProvider,
  groups: groupDataProvider,
  labs: labsDataProvider,
  metrics: metricDataProvider,
  move: moveDataProvider,
  one_on_ones: oneOnOneDataProvider,
  project_outcomes: projectOutcomesProvider,
  survey_comments: surveyCommentsProvider,
  student_clusters: studentClusterDataProvider,
  students: studentDataProvider,
  users: userDataProvider,
};
